import React, { useState, useEffect } from 'react';

export function DelayedLoader({
  children,
  delay = 500,
}: {
  children: React.ReactNode;
  delay?: number;
}) {
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(true);
    }, delay);

    // Clear the timer if the component unmounts
    return () => clearTimeout(timer);
  }, [delay]);

  if (!showLoader) {
    return null;
  }

  return <>{children}</>;
}
