import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix';
import {
  json,
  isRouteErrorResponse,
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
  useRouteLoaderData,
  useNavigation,
  useLocation,
} from '@remix-run/react';
import type { LinksFunction } from '@remix-run/node';
import { Loader } from 'lucide-react';

import './tailwind.css';
import { buildPublicEnv } from './utils/env';
import LiveChat from '~/components/LiveChat';
import { DelayedLoader } from './components/DelayedLoader';
import Fathom from './components/Fathom';
import GlobalsProvider from './components/GlobalsProvider';

//import * as gtag from '~/utils/gtags.client';
import { useEffect, useState } from 'react';

declare global {
  interface Window {
    dataLayer: unknown[];
  }
}

//TODO: move to utils/gtags.client.ts
function generateAnalyticsClientId() {
  // First number: random number between 100000000-999999999
  const first = Math.floor(Math.random() * 900000000) + 100000000;
  // Second number: current timestamp in seconds
  const second = Math.floor(Date.now() / 1000);
  return `${first}.${second}`;
}

export async function loader() {
  const publicEnv = buildPublicEnv();
  return json({ publicEnv });
}

export const links: LinksFunction = () => [
  { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
  {
    rel: 'preconnect',
    href: 'https://fonts.gstatic.com',
    crossOrigin: 'anonymous',
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap',
  },
  {
    rel: 'preconnect',
    href: 'https://www.googletagmanager.com',
  },
  {
    rel: 'preconnect',
    href: 'https://www.google-analytics.com',
  },
];

export function Layout({ children }: { children: React.ReactNode }) {
  const data = useRouteLoaderData<typeof loader>('root');
  const location = useLocation();
  const gaTrackingId = data?.publicEnv.GOOGLE_ANALYTICS_ID;
  const [analyticsClientId, setAnalyticsClientId] = useState<string | null>(
    null,
  );

  const getClientId = () => {
    if (typeof window === 'undefined') return null;

    const stored = localStorage.getItem('analytics_client_id');
    if (stored) return stored;

    const newClientId = generateAnalyticsClientId();
    localStorage.setItem('analytics_client_id', newClientId);
    return newClientId;
  };

  useEffect(() => {
    if (!gaTrackingId) return;

    const clientId = getClientId();
    setAnalyticsClientId(clientId);

    const isLocalhost = window.location.hostname === 'localhost';

    // Only send page view events for route changes
    if (window.gtag) {
      window.gtag('event', 'page_view', {
        page_title: document.title,
        page_location: window.location.href,
        page_path: window.location.pathname,
        debug_mode: isLocalhost,
        client_id: clientId,
      });
    }
  }, [location, gaTrackingId]);

  const gaScript = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    window.gtag = gtag;
    gtag('js', new Date());
    gtag('config', '${gaTrackingId}', {
      page_path: window.location.pathname,
      debug_mode: window.location.hostname === 'localhost',
      client_id: '${analyticsClientId || ''}'
    });
  `;

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="bg-white">
        {!gaTrackingId ? null : (
          <>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`}
            />
            <script
              async
              id="gtag-init"
              dangerouslySetInnerHTML={{
                __html: gaScript,
              }}
            />
          </>
        )}
        <GlobalsProvider
          env={data?.publicEnv ?? null}
          analyticsClientId={analyticsClientId}
        >
          {children}
          <ScrollRestoration />
          <Scripts />
          <LiveChat />
          <script
            async
            src="https://assets.churnkey.co/js/app.js?appId=hez3u42z4"
          />
          <Fathom />
        </GlobalsProvider>
      </body>
    </html>
  );
}

function App() {
  const { state } = useNavigation();

  if (state === 'loading') {
    return (
      <DelayedLoader delay={400}>
        <div className="h-auto w-auto flex items-center justify-center">
          <Loader className="my-28 h-16 w-16 text-primary/60 animate-spin" />
        </div>
      </DelayedLoader>
    );
  }

  return <Outlet />;
}

export default withSentry(App);

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);

  if (isRouteErrorResponse(error)) {
    return (
      <main className="mx-auto flex w-full max-w-7xl flex-auto flex-col justify-center px-6 py-24 sm:py-64 lg:px-8">
        <p className="text-base font-semibold leading-8">{error.status}</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          {error.status === 404 ? 'Page not found' : error.statusText}
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600">
          {error.status === 404
            ? "Sorry, we couldn't find the page you're looking for."
            : error.data}
        </p>
        <div className="mt-10">
          <Link to="/" className="text-sm font-semibold leading-6">
            <span aria-hidden="true">&larr;</span> Back to home
          </Link>
        </div>
      </main>
    );
  } else {
    return (
      <main className="mx-auto flex w-full max-w-7xl flex-auto flex-col justify-center px-6 py-24 sm:py-64 lg:px-8">
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          Well, this is awkward...
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600">
          Sorry, something went wrong on our end. We&apos;ve been notified and
          will fix it as soon as possible.
        </p>
        <div className="mt-10">
          <Link to="/" className="text-sm font-semibold leading-6">
            <span aria-hidden="true">&larr;</span> Back to home
          </Link>
        </div>
      </main>
    );
  }
};
