import { useLocation } from '@remix-run/react';
import { load, trackPageview } from 'fathom-client';
import { useEffect } from 'react';

const Fathom = () => {
  const location = useLocation();

  useEffect(() => {
    load('DVIPTIKD');
  }, []);

  useEffect(() => {
    // Replace CUID2 IDs with generic placeholders
    const genericPath = location.pathname.replace(
      /\/[a-z0-9]{24,32}(?=\/|$)/g,
      '/{id}',
    );
    trackPageview({
      url: genericPath,
    });
  }, [location.pathname, location.search]);

  return null;
};

export default Fathom;
